var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DefaultLayout',{scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('v-autocomplete',{ref:"autocomplete",attrs:{"label":"search","solo":"","light":"","hide-details":"","loading":_vm.isLoading,"items":_vm.suggestions,"search-input":_vm.searchText,"return-object":"","no-filter":"","chips":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(data.item.Description)?_c('span',{staticClass:"roboto-bold"},[_vm._v(_vm._s(data.item.PartNumber)+" - "+_vm._s(data.item.Description))]):_c('span',{staticClass:"roboto-bold"},[_vm._v(_vm._s(data.item.PO))])]}},{key:"item",fn:function(data){return [(data.item.header)?[_c('span',[_vm._v(_vm._s(data.item.header))])]:[(
              data.item.Description &&
                _vm.doesMatch(
                  `${data.item.PartNumber} - ${data.item.Description}`,
                  _vm.searchText
                )
            )?_c('span',{staticClass:"roboto-bold",domProps:{"innerHTML":_vm._s(
              _vm.formatMatchedString(`${data.item.PartNumber} - ${data.item.Description}`)
            )}}):_vm._e(),(data.item.PO && _vm.doesMatch(data.item.PO, _vm.searchText))?_c('span',{staticClass:"roboto-bold",domProps:{"innerHTML":_vm._s(_vm.formatMatchedString(`${data.item.PO}`))}}):_vm._e()]]}}]),model:{value:(_vm.selectedSuggestion),callback:function ($$v) {_vm.selectedSuggestion=$$v},expression:"selectedSuggestion"}})]},proxy:true},{key:"main",fn:function(){return undefined},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }