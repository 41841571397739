
import { OrdersService } from '@/services/orders-service';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import BasicHelpDialog from '@/components/BasicHelpDialog.vue';
import html2canvas from 'html2canvas';
import { FeedbackMsgModel } from '@/store/models/CustomerModel';
import AuthService from '@/services/auth-service';
@Component({
  components: { DefaultLayout, BasicHelpDialog }
})
export default class extends Vue {
  $refs!: {
    autocomplete: HTMLFormElement;
    helpDeskDialog: HTMLFormElement;
    anim: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  @Watch('searchText')
  onSearchTextChange(val: string) {
    val && val !== this.selectedSuggestion && this.getSuggestions();
  }
  /* Data */
  suggestions: any[] = [];
  selectedSuggestion: any = null;
  isLoading: boolean = false;
  searchText: string = '';
  typingTimer: any;
  doneTypingInterval: number = 250;
  radioGroup: number = 4;
  shouldShowDialog: boolean = false;
  x: number = 0;
  data: FeedbackMsgModel = new FeedbackMsgModel();
  rollDirectionOptions: any = [
    {
      title: '#4 unwind',
      description: 'Left side of label dispenses first. Wound Outside',
      src: require('@/assets/4unwind.svg'),
      value: 4
    },
    {
      title: '#3 unwind',
      description: 'Right side of label dispenses first. Wound Outside',
      src: require('@/assets/3unwind.svg'),
      value: 3
    }
  ];
  /* Utility Functions */
  moveShoppingCart() {
    this.$refs.anim.play();
  }
  resetShoppingCart() {
    this.$refs.anim.stop();
  }
  async takeScreenshot() {
    const canvas = await html2canvas(document.body);
    let img = canvas.toDataURL();
    const data: FeedbackMsgModel = {
      BrowserType: window.navigator.userAgent,
      Url: window.location.href,
      ScreenShot: img,
      ReportType: 'error',
      Message: 'Could not place order',
      SubmissionTime: ''
    };
    this.data = data;
    this.shouldShowDialog = true;
    document.body.appendChild(canvas);
  }
  async getSuggestions() {
    this.isLoading = true;
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(async () => {
      try {
        let model = {
          Take: 10,
          Skip: 0,
          Query: this.searchText
        };
        const response = await OrdersService.GetAutocompleteSuggestionsBySearchQuery(
          model
        );
        this.suggestions = [];
        this.suggestions = this.formatSuggestions(response.data);
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    }, this.doneTypingInterval);
  }
  formatSuggestions(suggestionList: any[]) {
    let matchedItems = suggestionList.filter(
      s =>
        this.doesMatch(s.Description, this.searchText) ||
        this.doesMatch(s.PartNumber, this.searchText)
    );
    let matchedPOs = suggestionList.filter(s => this.doesMatch(s.PO, this.searchText));
    let list = [
      { header: 'Items' },
      ...matchedItems,
      { header: 'Orders' },
      ...matchedPOs
    ];
    return list;
  }
  open() {
    this.$refs.helpDeskDialog.openDialog();
  }
  doesMatch(firstItem: string, secondItem: string) {
    if (!firstItem) return false;
    if (!secondItem) return true;
    let doesMatch = firstItem.toLowerCase().includes(secondItem.toLowerCase());
    return doesMatch;
  }
  formatMatchedString(stringToChange: string) {
    if (!this.searchText) return stringToChange;
    let location = stringToChange.toLowerCase().search(this.searchText.toLowerCase());
    let stringStart = stringToChange.substring(0, location);
    let stringEnd = stringToChange.substring(location + this.searchText.length, 9999);
    let stringMatch = stringToChange.substring(
      location,
      location + this.searchText.length
    );
    return `${stringStart}<span class="roboto-regular">${stringMatch}</span>${stringEnd}`;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
